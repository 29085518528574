<template>
  <v-container>
    <v-row>
      <p>
        {{
          "&nbsp;Let me begin this effort to dispel divisions with an appeal\
           to our common roots. Christ and his apostles. My aim is to show you\
            with a brief beginning effort here the ease with which we can see the beliefs\
             of the early Christians are in alignment with Catholics. You will see\
              they do not believe what you believe about Christ&#x2019;s teaching.\
               I would like to share this with you not by rattling off quotes as proof \
               texts from ancient men in whom you would not place any authority. Rather I\
                would like to study the historical value of their words for us both and begin \
                our discussion on this topic with a question. If you believe the Early Christians\
                 I cite preach something false about what Christ taught then where in the early\
                  church can you find your doctrines? Where do you find sola scriptura among the\
                   early Christians? Opposition to infant baptism? Opposition to purgatory?\
                    Opposition to veneration of Mary and the saints? Belief the Eucharist is\
                     only symbolic? If you believe what I share below is wrong, where are the\
                      men and women of God you see as faithful to Christ&#x2019;s teachings\
                       preserving the faith and opposing these errors you will see in the early \
                       Christians I cite? Our Lord promised the gates of Hell would not prevail\
                        against his Church so surely throughout history we can find right \
                        believing Christians."
        }}
      </p>
      <p>
        {{'If you have bible passages to refute the things I share below I\
        would certainly like to discuss the potential for deceptive\
        interpretations with you, but before we begin on that course why is it\
        that Luther, Calvin or perhaps'}} <em>{{'you'}}</em> {{'are the first individual to\
        refute a Catholic doctrine with a certain biblical passage. Do you have\
        some piece of Early Christian History unknown to Catholics? Or perhaps\
        the Holy Spirit chose to wait centuries prior to correcting the errors\
        of the Early Church? If you believe the Catholic Church has errors\
        what follows is you must see those errors have been present since the\
        dawn of recorded Christian history. If you would disagree with me today\
        and seek to draw me out of a deception, where are your forebears\
        seeking to do\ the same for the men I cite below?'}}
      </p>
      <p>
        <strong>{{ "The Eucharist:" }}</strong>
      </p>
      <blockquote>
        <p class="blockquote">
          <em>{{
            '“We call this food Eucharist, and no one else is permitted to partake \
            of it, except one who believes our teaching to be true and who has been washed \
            in the washing which is for the remission of sins and for regeneration \
            [i.e., has received baptism] and is thereby living as Christ enjoined. \
            For not as common bread nor common drink do we receive these; but since Jesus Christ\
             our Savior was made incarnate by the word of God and had both flesh and\
              blood for our salvation, so too, as we have been taught, the food which \
              has been made into the Eucharist by the Eucharistic prayer set down by him,\
               and by the change of which our blood and flesh is nurtured, is both the\
               flesh and the blood of that incarnated Jesus”'
          }}</em
          >{{ "(" }}<em>{{ "First Apology&nbsp;" }}</em
          >{{ "66 [A.D. 151])." }}
        </p>
      </blockquote>
      <p>
        {{
          "Why is a Catholic in 151 AD, Justin Martyr, talking bread and wine being\
           changed, by a Eucharistic prayer, and explaining it becomes not ordinary\
            food but the flesh and blood of that incarnate Jesus?"
        }}
      </p>
      <blockquote class="blockquote">
        <em
          ><p>
            {{
              '“What you see is the bread and the chalice; that is what your\
               own eyes report to you. But what your faith obliges you to accept\
                is that the bread is the body of Christ and the chalice is the blood \
                of Christ. This has been said very briefly, which may perhaps be sufficient\
                 for faith; yet faith does not desire instruction”'
            }}
            (<em>{{ "Sermons&nbsp;" }}</em
            >{{ "272 [A.D. 411])." }}
          </p>
        </em>
      </blockquote>
      <p>
        {{
          "A name you perhaps are more familiar with, St. Augustine,\
           a Catholic Bishop whom we have extensive writings from. Is\
            Augustine confused like you might say Catholics are by \
            this passage in John:"
        }}
      </p>
      <blockquote class="blockquote">
        <em>
          <p>
            {{
              '“&#x2018;I am the living bread which came down from heaven; \
              if any one eats of this bread, he will live forever; and the \
              bread which I shall give for the life of the world is my flesh.&#x2019; \
              The Jews then disputed among themselves, saying, &#x2018;How can \
              this man give us his flesh to eat?&#x2019;” (John 6:51&#x2013;52)'
            }}
          </p></em
        >
      </blockquote>
      <p>
        {{
          "We have entire books from Augustine. If you believe he is wrong, \
          the Eucharist is merely symbolic, and Christ taught it was symbolic \
          then I think it reasonable to expect we would see the Holy Spirit \
          raise up a faithful Christians to oppose Augustine&#x2019;s error \
          or at least someone of the same time period saying what you are saying.\
           I would challenge you to look for a likeminded forebearer in this regard."
        }}
      </p>
      <p>
        {{
          "Much more could be said, but again my aim is to simply begin a conversation.\
           My questions will be the same for other doctrines I expect we disagree upon.\
            I will leave you with just a few other quotes to study further their\
             authors and context."
        }}
      </p>
      <p>
        <strong>{{ "Purgatory" }}</strong>
      </p>
      <blockquote class="blockquote">
        <em>
          <p>
            {{
              '“Let us help and commemorate them. If Job&#x2019;s sons were purified\
               by their father&#x2019;s sacrifice [Job 1:5], why would we doubt that\
                our offerings for the dead bring them some consolation? Let us not\
                 hesitate to help those who have died and to offer our prayers for them” ('
            }}<em>{{ "Homilies on First Corinthians&nbsp;" }}</em
            >{{ "41:5 [A.D. 392])." }}
          </p>
        </em>
      </blockquote>
      <p>
        {{
          "Why is the 4th&nbsp;century Archbishop of Constantinople, St John Chrysostom,\
           preaching on something that sounds like purgatory and citing Job as scripture\
            to do so?"
        }}
      </p>
      <p>
        <strong>{{ "Papacy" }}</strong>
      </p>
      <blockquote class="blockquote">
        <em>
          <p>
            {{
              '“If the very order of episcopal succession is to be considered,\
               how much more surely, truly, and safely do we number them \
               [the bishops of Rome] from Peter himself, to whom, as to one representing \
               the whole Church, the Lord said, &#x2018;Upon this rock I will build my Church,\
                and the gates of hell shall not conquer it.&#x2019; Peter was succeeded by Linus,\
                 Linus by Clement. … In this order of succession, a Donatist bishop is not to be found” ('
            }}<em>{{ "Letters&nbsp;" }}</em
            >{{ "53:1:2 [A.D. 412])." }}
          </p>
        </em>
      </blockquote>
      <p>
        {{
          "Why is it so important to Augustine who the successor of Peter is?"
        }}
      </p>
    </v-row>
    <v-row>
      <p>
        <strong>{{ 'Veneration of Mary'}}</strong>
      </p>
      <blockquote class="blockquote">
        <em>
          <p>
            {{
              '“Come, then, and search out your sheep, not through your servants\
            or hired men, but do it yourself. Lift me up bodily and in the\
            flesh, which is fallen in Adam. Lift me up not from Sarah but from\
            Mary, a virgin not only undefiled, but a virgin whom grace had made\
            inviolate, free of every stain of sin” ('
            }}
            <em>{{ "Commentary on Psalm 118:" }}</em
            >{{ "22&#x2013;30 [A.D. 387])." }}
          </p></em
        >
      </blockquote>
      <p>
        {{
          "Why is Ambrose speaking of Mary being free from every stain of sin in 387 AD?"
        }}
      </p>
      <p>
        {{
          "You might want to simply pass over the witness of the Early Christians\
           here and turn to scripture. I would ask you, if you like me seek to be\
            of one mind in Christ, to explain what happened here. What happened to\
             those St. Paul taught? Did they all go astray? Timothy and Titus? \
             Did they not pass on what Paul gave them correctly? If you say some went\
              astray surely you don&#x2019;t believe all went astray? Surely you cannot\
               believe the Holy Spirit would allow the light of faith to go out.\
                If what I present as Christ&#x2019;s teaching through these Early Christians\
                 is not true show me where truth was preserved. Our Lord told us the gates\
                  of Hell would not prevail against his Church. Surely you don&#x2019;t believe he lied."
        }}
      </p>
  
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ArticleTwo",
};
</script>
