<template>
  <v-container>
    <v-row
      ><p>{{
        '&nbsp;The heart of most of our differences I imagine will stem from\
        different interpretations of scripture. On the same thread as the\
        treatise on the Early Christians I would be curious to understand the\
        origins of our interpretative differences. Are you and I the first to\
        disagree on whichever passage you may want to look at? Or does it go\
        back to the 1500s? Or further?'}}
      </p>
      <p>
        {{'The history of when books of the bible were written is contested to some\
        degree, pinpoint accuracy is not my aim here. Let&#x2019;s recap a\
        history of the canon of scripture briefly.'}}
      </p>
      <ul>
      <li>
        {{'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The new testament\
        books of the bible we agree on are commonly believed to have been\
        written between 50 AD and 100 AD.'}}
      </li>
      <br />
      <li>
        {{'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the next\
        couple hundred years the canon of scripture was debated quite a bit.'}}
      </li>
      <br />
      <li>
        {{'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Notably during\
        this time without a canon of scripture, sola scriptura would have been\
        quite interesting in application for those of you who adhere to that\
        belief.'}}
      </li>
      <br />
      <li>
        {{'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Over time you see\
        a consensus start to form on the canon of scripture and a notable\
        milestone is the Council of Hippo in 393.'}}
      </li>
      <br />
      <li>
        {{'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the 1500s\
        Martin Luther removed the old testament books you and I would disagree\
        on and your bible is different to this day with some winds and twists\
        along the way.'}}
      </li>
      </ul>
      <br />
    </v-row>
    <v-row>
      <p>
        {{'Some questions seem worth discussing after reflecting on this early\
        history of Holy Scriptures:'}}
      </p>
      <p>
        {{'How do you know you have the correct books in your bible? What I would\
        call the Deuterocanonical books and you would call the apocrypha was\
        considered by all Christians to be the inspired word of God from the\
        time the canon was set until the time of Luther. Would the Holy Spirit\
        wait over 1000 years to make this correction if those books were not\
        scripture?'}}
      </p>
      <p>
        {{'If this topic is one you wish to speak about I would also recommend you\
        read this series of articles from a former protestant pastor. He\
        explains what persuaded him sola scriptura is false. If you don&#x2019;t\
        find his argument convincing I imagine we can have a fruitful\
        conversation about why not.'}}
      </p>
      <p>
        <a
          href="https://chnetwork.org/2018/02/06/im-catholic-sola-scriptura-isnt-scriptural/"
          target="_blank"
          >{{'https://chnetwork.org/2018/02/06/im-catholic-sola-scriptura-isnt-scriptural/'}}</a
        >
      </p>
      <p>
        {{'Again, my aim is certainly to persuade you with these arguments, but\
        should we get the chance to speak in person I hope to hear your replies\
        to the questions above. We are having a 500-year-old argument, and while\
        both of us can&#x2019;t be correct it must be a rather challenging\
        argument to resolve if 500 years hasn&#x2019;t been enough time to sort\
        it out. Yet with God&#x2019;s help I believe we can be of one mind!'}}
      </p>
      <p>&nbsp;</p>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "ArticleThree",
};
</script>
