<template>
  <v-container>
    <v-row>
      <p>
        {{
          " I ended my last letter by emphasizing we are having a 500-year-old\
        argument. It seems a bit odd that people could disagree about anything\
        for that long, yet here we are. It could also be a bit discouraging in\
        attempts to try and resolve the differences. If it hasn&#x2019;t been\
        resolved in 500 years why try, we might ask"
        }}
      </p>
      <p>
        {{
          " If this argument hasn&#x2019;t been resolved in 500 years then it seems\
        clear to conclude we won&#x2019;t resolve it of our own human reasonings\
        and argumentations. We are talking about matters of faith here after all\
        and faith is a gift. All throughout scripture we see the gift of faith\
        tied to miracles. An act of faith expressed to Jesus always proceeds his\
        power flowing forth to heal the sick, give sight to the blind, etc.\
        These same miracles also beget more faith, as we see in those who\
        witness the miracles. Thomas does not believe but placing his hand in\
        our Lords wounds helps him believe."
        }}
      </p>
      <p>
        {{
          "In this context I would like to explain a few examples of how our Lord\
        has for the last 2000 years and continues to this day to perform\
        miracles that help us have faith the Catholic Church is his Church.\
        &nbsp;&nbsp;"
        }}
      </p>
      <p>
        <strong>{{
          "He changes bread and wine into his visible body and blood so that we\
          might actually see what normally must be seen with the eyes of\
          faith:"
        }}</strong>
      </p>
    </v-row>
    <v-row>
      <p>
        <a
          href="https://dowym.com/voices/5-incredible-eucharistic-miracles-from-the-last-25-years/"
          target="_blank"
          >{{
            "https://dowym.com/voices/5-incredible-eucharistic-miracles-from-the-last-25-years/"
          }}</a
        >
      </p>
    </v-row>
    <v-row>
      <p>
        <a href="https://www.youtube.com/watch?v=gDzhdlcp98M" target="_blank">{{
          "https://www.youtube.com/watch?v=gDzhdlcp98M"
        }}</a>
      </p>
    </v-row>
    <v-row>
      <p>
        <strong>{{
          "He sends his Mother to convert entire nations and leaves evidence to\
          convince us to this day:"
        }}</strong>
      </p>
    </v-row>
    <v-row>
      <p>
        <em>{{ "Guadalupe" }}</em>
      </p>
    </v-row>
    <v-row>
      <p>
        <a
          href="https://denvercatholic.org/our-lady-of-guadalupe-the-miracle-that-changed-history/"
          target="_blank"
          >{{
            "https://denvercatholic.org/our-lady-of-guadalupe-the-miracle-that-changed-history/"
          }}</a
        >
      </p>
    </v-row>
    <v-row>
      <p>
        {{
          "More times than once he has sent her. Sometimes the evidence left is set\
        in stone:"
        }}
      </p>
    </v-row>
    <v-row>
      <p>
        <em>{{ "Our Lady of Las Lajas" }}</em>
      </p>
    </v-row>
    <v-row>
      <p>
        <a
          href="https://catholicexchange.com/miraculous-image-lady-las-lajas"
          target="_blank"
          >{{
            "https://catholicexchange.com/miraculous-image-lady-las-lajas"
          }}</a
        >
      </p>
    </v-row>
    <v-row>
      <p>
        {{
          "One step further when he sends her she converts even the greatest of\
        skeptics to her son:"
        }}
      </p>
    </v-row>
    <v-row>
      <p>
        <em>{{ "Fatima" }}</em>
      </p>
    </v-row>
    <v-row>
      <p>
        <a
          href="https://www.catholicnewsagency.com/saint/our-lady-of-fatima-485"
          target="_blank"
          >{{
            "https://www.catholicnewsagency.com/saint/our-lady-of-fatima-485"
          }}</a
        >
      </p>
      <p>
        {{
          " If you have read this far and sincerely out of love for our Lord wish to\
        heed his prayer for us recorded in&nbsp;"
        }}<strong>{{ "John 17:21&nbsp;" }}</strong
        >{{
          "I hope you will spend some time reading about these miracles and\
        discerning their origin. To think they were faked or people are mistaken\
        about what they saw is not a tenable position if you take a sincere look\
        at the evidence left. Which leaves you with two options: A bad spirit or\
        the Holy Spirit is the origin of these miracles.&nbsp; All of Mexico\
        became Catholic because of the miraculous tilma of our Lady of\
        Guadalupe. Millions know Christ because of this, a beautiful fruit."
        }}
      </p>
      <p>
        {{
          "You can probably share stories of miracles from calling on the power of\
        God with faith in the name of Jesus and I wouldn&#x2019;t doubt them\
        outright. I know of many like it from personal experience and the lives\
        of Catholic saints. What I hope you can discern is different about the\
        ones above is how their nature gives witness to unique Catholic beliefs.\
        They are aides from heaven to help us have faith in what Christ taught.\
        I hope you will discern and pray about them."
        }}
      </p>
      <p>
        {{
          "Thank you for reading this far if you have. Should you wish to reply to\
        something written here or perhaps ask about a different topic please\
        know of my promise to listen attentively and try to reply. A battle of\
        ideas for prides sake is not my aim. My motive is to make Christ more\
        known and if our divisions are obstacles to others coming to know him I\
        would try to do my part to resolve that issue. I look forward to\
        speaking with you."
        }}
      </p></v-row
    >
  </v-container>
</template>
<script>
export default {
  name: "ArticleFour",
};
</script>
