<template>
  <v-container>
    <base-card>
      <v-container>
        <base-card tile flat class="ma-4">

          <v-row class="d-flex">
            <v-spacer />
            <v-card height="500px" width="500px" color="black" elevation="8" class="justify-center">
              <v-card height="500px" width="500px" color="black" elevation="8" class="justify-center">
                <div style="height: 100%; width: 100%;" v-html="conversion"></div>
              </v-card>

            </v-card>
            <v-spacer />
          </v-row>
          <v-row>
            <v-col>
              <h2 class="text4 text-wrap" style="font-size:25px;">
                <p>
                  {{
                    "Why don&#x2019;t all Christians believe the same things about what Jesus\
                                    taught? Perhaps a more common question today is why does it matter? \
                                    Our Lord himself gives us a reason it matters:"
                  }}
                </p>
                <p>
                  {{
                    '&#34;And not for them only do I pray, but for them also who through\
                                    their word shall believe in me; That they all may be one, as thou,\
                                    Father, in me, and I in thee; that they also may be one in us;'
                  }}
                  <strong>{{
                    "that the world may believe that thou hast sent me.”&nbsp;John 17:\
                                      21"
                  }}</strong>
                </p>
                <p>
                  {{ `This is Our Lord praying for us, just before he goes to die for us.\
                  Not for us to tolerate each other, but that we “be one”.\
                  To quote Saint Paul: “Fulfill ye my joy, that you may be of one mind.”\
                  Philipians 2:2`}}
                </p>
                <p>
                  {{
                    "At a time when the earth seems determined to tear itself apart,\
                                    we are convinced that a reunion of Christians can be a powerful\
                                    and necessary witness to a broken world. Out of love for our\
                                    Lord Jesus Christ then, we invite you to join us for coffee\
                                    and discuss the truth and unity which He has prayed for."
                  }}
                </p>
              </h2>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-spacer />
            <v-card height="500px" width="500px" color="black" elevation="8" class="justify-center">
              <v-card height="500px" width="500px" color="black" elevation="8" class="justify-center">
                <div style="height: 100%; width: 100%;" v-html="miracles"></div>
              </v-card>

            </v-card>
            <v-spacer />
          </v-row>
        </base-card>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
export default {
  name: "HomeIntroSnip",
  data() {
    return {

      conversion: '<iframe src="https://www.youtube.com/embed/lfKJqGLcPi8"width="100%" height="100%" frameborder="0" allowfullscreen ></iframe>', // Removed 'position:absolute; left:0px; top:0px;'


      miracles: '<iframe src="https://www.youtube.com/embed/QD7vZTVVmDU"width="100%" height="100%" frameborder="0" allowfullscreen ></iframe>',


    };
  },
};
</script>

