<!-- <template>
  <v-container id="contact" tag="section">
    <base-card >
      <v-container>
        <base-card flat class="ma-4">
          <v-row>
            <v-col align="center">
              <base-subheading>Contact Us</base-subheading></v-col
            >
          </v-row>
          <v-col align="center" class="text4" style="font-size: 25px">
            <ModalView :showIcon="2" />
          </v-col>
        </base-card>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
import ModalView from "../modal/ModalView.vue";

export default {
  name: "HomeContact",

  components: {
    ModalView,
  },

};
</script> -->
<template>
<v-container id="contact" tag="section">
  <base-card >
    <v-container>
      <base-card flat class="ma-4">
        <v-row>
          <v-col align="center">
            <base-subheading>Contact Us</base-subheading></v-col
          >
        </v-row>
        <v-col align="center" class="text4" >
          <v-btn @click="sendEmail" style="font-size: 25px"><v-icon color="black" size="30">mdi-email</v-icon>  E-Mail</v-btn>
        </v-col>
      </base-card>
    </v-container>
  </base-card>
</v-container>
</template>


<script>
export default {

  name: "HomeContact",
  methods: {
    sendEmail() {
      window.location.href = 'mailto:derekroush11@gmail.com';
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>

  

