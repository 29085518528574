<template>
  <div>
    <v-sheet color="rgb(65, 105, 225)">
      <v-container>
        <base-card color="rgb(255,215,0)">

          <v-container>
            <base-card flat tile color="transparent">
              <v-img max-height="350" src="../assets/last-supper.svg">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      size="320"
                      color="white"
                    ></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </base-card>
          </v-container>
        </base-card>
      </v-container>
    </v-sheet>
    <v-sheet height="10" color="black" />
    <inro-snip />
    <v-sheet height="75" color="rgb(240,248,255)" />
    <about-us />
    <preview />
    <v-sheet height="75" color="rgb(240,248,255)" />
    <contact />
    <v-sheet height="75" color="rgb(240,248,255)"/>
  </div>
</template>

<script>
import Preview from "../components/home/Preview.vue";
import Contact from "../components/home/Contact.vue";
import InroSnip from "../components/home/IntroSnip.vue";
import AboutUs from "../components/home/AboutUs.vue";
export default {
  name: "HomeSection",

  components: {
    Preview,
    Contact,
    InroSnip,
    AboutUs,
  },
};
</script>
