<template>
  <v-container id="letter" tag="section">
    <v-sheet height="75" color="rgb(240,248,255)" />
    <base-card>
      <v-container>
        <base-card flat class="ma-2">
          <v-row class="py-16">
            <v-col align="center">
              <h2 class="text3 text-wrap">
                {{ articleTitle }}
              </h2>
            </v-col>
            <h3 class="text4 text-wrap ma-4">
              <article-two v-if="articleId == 2" />
              <article-three v-else-if="articleId == 3" />
              <article-four v-else-if="articleId == 4" />
            </h3>
            <v-col align="center" justify="start">
              <v-chip
                color="#272727"
                class="text2"
                label
                dark
                large
                :to="{ path: '/#preview' }"
              >
                Return Home
              </v-chip>
            </v-col>
          </v-row>
        </base-card>
      </v-container>
    </base-card>
    <v-sheet height="75" color="rgb(240,248,255)" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ArticleTwo from "../articles/ArticleTwo.vue";
import ArticleThree from "../articles/ArticleThree.vue";
import ArticleFour from "../articles/ArticleFour.vue";

export default {
  components: { ArticleTwo, ArticleThree, ArticleFour },
  name: "LetterFeed",

  beforeMount() {
    if (this.getArticle) {
      localStorage.articleId = this.getArticle.id;
      localStorage.articleTitle = this.getArticle.title;
    }
  },

  computed: {
    ...mapGetters(["getArticle"]),

    articleId() {
      if (localStorage.articleId) {
        return localStorage.articleId;
      } else {
        return this.getArticle.id;
      }
    },
    articleTitle() {
      if (localStorage.articleTitle) {
        return localStorage.articleTitle;
      } else {
        return this.getArticle.title;
      }
    },
  },
};
</script>
