<template>

      <event-page class="ma-0" />

</template>

<script>
import EventPage from "./EventPage.vue";

export default {
  name: "HomeMeetUp",

  components: {
    EventPage,
  },
};
</script>
